// Generated by Framer (1cbc8a2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./fM5fSWcA7-0.js";

const enabledGestures = {mNrWjmyTK: {hover: true}};

const cycleOrder = ["mNrWjmyTK"];

const serializationHash = "framer-sxB2E"

const variantClassNames = {mNrWjmyTK: "framer-v-1drud4u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({fotoCitt_, h1, height, id, width, ...props}) => { return {...props, DHngAbtIV: fotoCitt_ ?? props.DHngAbtIV, QKhOPnfhm: h1 ?? props.QKhOPnfhm ?? "Ancona"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;fotoCitt_?: {src: string; srcSet?: string};h1?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DHngAbtIV, QKhOPnfhm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mNrWjmyTK", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "FZXzj1kYA"}}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1drud4u", className, classNames)} framer-1s5xsj4`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mNrWjmyTK"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", ...style}} variants={{"mNrWjmyTK-hover": {backgroundColor: "rgb(245, 245, 245)"}}} {...addPropertyOverrides({"mNrWjmyTK-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "92px", ...toResponsiveImage(DHngAbtIV)}} className={"framer-ub5g6"} layoutDependency={layoutDependency} layoutId={"PP0I2eVYz"} style={{borderBottomLeftRadius: 46, borderBottomRightRadius: 46, borderTopLeftRadius: 46, borderTopRightRadius: 46}}/><motion.div className={"framer-1ntnjrv"} data-framer-name={"Post"} layoutDependency={layoutDependency} layoutId={"sHY72Yjdy"}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "28px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.28px", "--framer-line-height": "36px"}}>Ancona</motion.h2></React.Fragment>} className={"framer-19sdayo"} data-framer-name={"H1"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"tyBFgNCGf"} text={QKhOPnfhm} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sxB2E.framer-1s5xsj4, .framer-sxB2E .framer-1s5xsj4 { display: block; }", ".framer-sxB2E.framer-1drud4u { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: wrap; gap: 40px; height: 120px; justify-content: flex-start; padding: 20px; position: relative; text-decoration: none; width: 600px; }", ".framer-sxB2E .framer-ub5g6 { flex: none; height: 92px; position: relative; width: 92px; }", ".framer-sxB2E .framer-1ntnjrv { align-content: flex-start; align-items: flex-start; display: flex; flex: 1 0 0px; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; min-width: 200px; padding: 0px; position: relative; width: 1px; }", ".framer-sxB2E .framer-19sdayo { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sxB2E.framer-1drud4u, .framer-sxB2E .framer-1ntnjrv { gap: 0px; } .framer-sxB2E.framer-1drud4u > * { margin: 0px; margin-left: calc(40px / 2); margin-right: calc(40px / 2); } .framer-sxB2E.framer-1drud4u > :first-child { margin-left: 0px; } .framer-sxB2E.framer-1drud4u > :last-child { margin-right: 0px; } .framer-sxB2E .framer-1ntnjrv > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-sxB2E .framer-1ntnjrv > :first-child { margin-top: 0px; } .framer-sxB2E .framer-1ntnjrv > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 120
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"VSRCC2Xof":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DHngAbtIV":"fotoCitt_","QKhOPnfhm":"h1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfM5fSWcA7: React.ComponentType<Props> = withCSS(Component, css, "framer-sxB2E") as typeof Component;
export default FramerfM5fSWcA7;

FramerfM5fSWcA7.displayName = "Store-list-item";

FramerfM5fSWcA7.defaultProps = {height: 120, width: 600};

addPropertyControls(FramerfM5fSWcA7, {DHngAbtIV: {title: "Foto Città", type: ControlType.ResponsiveImage}, QKhOPnfhm: {defaultValue: "Ancona", title: "H1", type: ControlType.String}} as any)

addFonts(FramerfM5fSWcA7, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})